import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../../contexts";
import { AuthContext } from "../../../contexts/AuthContext";
import { Text } from "../../atoms";
import { Colors } from "./Colors";
import { FaMobileAlt } from "react-icons/fa";
import {
  BsBox,
  BsHouseDoor,
  BsClipboardData,
  BsChatLeftText,
  BsDoorClosed,
} from "react-icons/bs";
import { MdOutlineAccessTime } from "react-icons/md";
import { IoTicketOutline } from "react-icons/io5";
import { PiLockers, PiBird } from "react-icons/pi";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FiUsers, FiUser } from "react-icons/fi";
import { GiProgression } from "react-icons/gi";
import { RiStarSLine } from "react-icons/ri";
import { AiOutlineGlobal } from "react-icons/ai";
import { ImFilesEmpty } from "react-icons/im";
import { VscKey } from "react-icons/vsc";

const LineDivider = ({ noGap = false, strong = false }) => (
  <Box
    style={{
      width: "100%",
      height: "1px",
      backgroundColor: strong ? Colors.darkGreen : "#ffffff18",
      ...(!noGap && { marginTop: 10, marginBottom: 10 }),
      opacity: strong ? 0.2 : 1,
    }}
  />
);

const TotalBadge = ({ total = 0, itemSelected = false }) => (
  <Box
    sx={{
      display: "flex",
      padding: `2px 6px`,
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      borderRadius: 1,
      ...(!itemSelected && {
        backgroundImage: `linear-gradient(to right, transparent, #ffffff04, #ffffff08, #ffffff12)`,
      }),
    }}
  >
    <Text
      style={{
        fontSize: 12,
        color: itemSelected ? Colors.darkGreen + "bb" : "#ffffff65",
      }}
    >
      {total}
    </Text>
  </Box>
);

export const LeftMenu = () => {
  const { appVersion } = useContext(AppContext);
  const {
    user,
    statistics: { totals = {} },
  } = useContext(AuthContext);
  const { permission: userPermission = [] } = user;

  const [showDropDown, setShowDropDown] = useState({
    active: false,
    section: "reports",
  });

  const MenuItem = (props) => {
    const {
      children,
      to = null,
      icon: Icon,
      dropDownParent = false,
      dropDownItem = false,
      onClick = () => setShowDropDown({ ...showDropDown, active: false }),
      total = null,
      name,
    } = props;

    let pathname = useLocation().pathname.split("/");
    pathname = dropDownItem
      ? `/${pathname[1]}/${pathname[2]}`
      : `/${pathname[1]}`;
    const isCurrentPath = pathname === to;

    return (
      <Box style={styles.leftMenuContainer}>
        <Link to={to} style={styles.link} onClick={onClick}>
          <ListItemButton
            sx={{
              ...styles.menuItem,
              backgroundColor: dropDownItem ? "#ffffff09" : "transparent",
              padding: `6px 16px`,
              ...(isCurrentPath && {
                backgroundColor: Colors.background,
                "&:hover": {
                  backgroundColor: Colors.background,
                  cursor: "pointer",
                },
              }),
            }}
          >
            <ListItemText
              primary={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1.5,
                    paddingRight: 1,
                  }}
                >
                  {Icon && (
                    <Icon
                      color={isCurrentPath ? Colors.darkGreen : "#ccc"}
                      size={16}
                    />
                  )}
                  <Box sx={{ display: "flex", flex: 1 }}>
                    <Text
                      style={{
                        ...styles.text,
                        ...(isCurrentPath && { color: Colors.darkGreen }),
                      }}
                    >
                      {children}
                    </Text>
                  </Box>
                  {dropDownParent && (
                    <Box
                      sx={{
                        ...styles.menuIcon,
                        width: 12,
                        height: 12,
                        backgroundImage: `url('/icons/gray_arrow_up.png')`,
                        transform:
                          showDropDown.active && showDropDown.section === name
                            ? `rotate(180deg)`
                            : `rotate(90deg)`,
                      }}
                    />
                  )}
                  {total && (
                    <TotalBadge total={total} itemSelected={isCurrentPath} />
                  )}
                </Box>
              }
            />
          </ListItemButton>
        </Link>
      </Box>
    );
  };

  return (
    <Box sx={styles.leftMenuMainContainer}>
      <List style={styles.container}>
        <Link
          to="/"
          onClick={() => setShowDropDown({ ...showDropDown, active: false })}
        >
          <Box sx={styles.logoContainer}>
            <img
              src="/images/TUIMBOX_LOGO_RGB_NEGATIVO_2.svg"
              alt="TuimBox Logo"
              style={{ height: "90%", width: "85%", objectFit: "contain" }}
            />
          </Box>
        </Link>
        {userPermission.includes("Administrador") && (
          <>
            <MenuItem to="/dashboard" icon={GiProgression}>
              Dashboard
            </MenuItem>
            <MenuItem to="/clients" icon={FiUsers} total={totals?.clients}>
              Clientes
            </MenuItem>
            <MenuItem
              to="/contracts"
              icon={ImFilesEmpty}
              total={totals?.contracts}
            >
              Contratos
            </MenuItem>
            <MenuItem to="/products" icon={BsBox} total={totals?.products}>
              Produtos
            </MenuItem>

            <MenuItem
              to="/rental-periods"
              icon={MdOutlineAccessTime}
              total={totals?.rentalPeriods}
            >
              Períodos
            </MenuItem>

            <MenuItem to="/lockers" icon={PiLockers} total={totals?.lockers}>
              Lockers
            </MenuItem>
            <MenuItem to="/doors" icon={BsDoorClosed} total={totals?.doors}>
              Portas
            </MenuItem>
            <MenuItem
              to="/vouchers"
              icon={IoTicketOutline}
              total={totals?.vouchers}
            >
              Vouchers
            </MenuItem>
            <MenuItem to="/homekeys" icon={VscKey} total={totals?.homekeys}>
              Chaves
            </MenuItem>
            <MenuItem
              to="/building-unit"
              icon={BsHouseDoor}
              total={totals?.approvedUnits}
            >
              Unidades
            </MenuItem>
            <MenuItem
              icon={FaMobileAlt}
              dropDownParent
              name="app"
              onClick={() =>
                setShowDropDown({
                  ...showDropDown,
                  active: !showDropDown.active,
                  section: "app",
                })
              }
            >
              App
            </MenuItem>
            {showDropDown.active && showDropDown.section === "app" && (
              <>
                <MenuItem
                  dropDownItem
                  to="/app/contract"
                  icon={ImFilesEmpty}
                  onClick={() =>
                    setShowDropDown({ ...showDropDown, active: true })
                  }
                >
                  Contrato
                </MenuItem>
              </>
            )}
            <MenuItem
              icon={BsClipboardData}
              dropDownParent
              name="reports"
              onClick={() =>
                setShowDropDown({
                  ...showDropDown,
                  active: !showDropDown.active,
                  section: "reports",
                })
              }
            >
              Relatórios
            </MenuItem>
            {showDropDown.active && showDropDown.section === "reports" && (
              <>
                <MenuItem
                  dropDownItem
                  to="/relatorios/avaliacoes"
                  icon={RiStarSLine}
                  onClick={() =>
                    setShowDropDown({ ...showDropDown, active: true })
                  }
                  total={totals?.evaluations}
                >
                  Avaliações
                </MenuItem>
                <MenuItem
                  dropDownItem
                  to="/relatorios/sugestoes"
                  icon={HiOutlineLightBulb}
                  onClick={() =>
                    setShowDropDown({ ...showDropDown, active: true })
                  }
                  total={totals?.suggestions}
                >
                  Sugestões
                </MenuItem>
                <MenuItem
                  dropDownItem
                  to="/relatorios/feedbacks"
                  icon={BsChatLeftText}
                  onClick={() =>
                    setShowDropDown({ ...showDropDown, active: true })
                  }
                  total={totals?.feedbacks}
                >
                  Feedbacks
                </MenuItem>
                <MenuItem
                  dropDownItem
                  to="/relatorios/macro"
                  icon={AiOutlineGlobal}
                  onClick={() =>
                    setShowDropDown({ ...showDropDown, active: true })
                  }
                >
                  Macro
                </MenuItem>
                <MenuItem
                  dropDownItem
                  to="/relatorios/tuim"
                  icon={PiBird}
                  onClick={() =>
                    setShowDropDown({ ...showDropDown, active: true })
                  }
                >
                  Contratos Tuim
                </MenuItem>
              </>
            )}
            <LineDivider />
            <MenuItem to="/users" icon={FiUser} total={totals?.CRMUsers}>
              Usuários
            </MenuItem>
          </>
        )}

        {userPermission.includes("BuildingManager") && (
          <>
            <MenuItem to="/dashboard" icon={GiProgression}>
              Lockers
            </MenuItem>
          </>
        )}
      </List>
      <Box sx={styles.versionContainer}>
        <Text xsmall="true" style={{ color: "#ffffff55" }}>
          {appVersion}
        </Text>
      </Box>
    </Box>
  );
};

const styles = {
  leftMenuMainContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: Colors.darkGreen,
  },
  leftMenuContainer: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    position: "relative",
    width: 220,
    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
  text: {
    fontSize: 14,
    color: "#ffffffaa",
  },
  link: {
    textDecoration: "none",
    color: "#000",
  },
  menuItem: {
    marginLeft: 2,
    borderRadius: `8px 0px 0px 8px`,
    "&:hover": {
      backgroundColor: "#ffffff22",
      cursor: "pointer",
    },
  },
  dropDownParent: {
    height: 45,
    paddingLeft: 3,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#6b858e24",
      cursor: "pointer",
    },
  },
  menuIcon: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 18,
    height: 18,
    "&:hover": {
      cursor: "pointer",
    },
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "20px 0px 10px 0px",
  },
  versionContainer: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: 1,
    backgroundColor: Colors.darkGreen,
    boxSizing: "border-box",
  },
};
