import { useEffect, useReducer, useState } from "react";
import { numberToBRL } from "../../../helpers/Number_to_BRL";
import { titleCase } from "../../../helpers/titleCase";
import api from "../../../services/api";
import {
  AnimatedNumber,
  ContentContainer,
  SectionHeader,
} from "../../W3libComponents";
import { Box, DatePicker, Text } from "../../atoms";
import { Divider } from "../../atoms/Divider";
import { PrimaryButton } from "../../organisms/button/Buttons";
import { Colors } from "../../organisms/layout/Colors";
import { DropDown } from "../../organisms/layout/DropDown";
import { Table } from "../../organisms/table/Table";
import { renameField } from "./locker_rename_field_helper";

const productsTableHeader = [
  { text: "Produto" },
  { text: "Contratos" },
  { text: "Total" },
];

export const Dashboard = (props) => {
  const { context } = props;
  const {
    user: { permission = [], lockers = [] },
    alert,
    setLoading,
  } = context;
  const userIsAdmin = permission.includes("Administrador");

  const [disableButton, setDisableButton] = useState(true);
  const [selectedLockerData, setSelectedLockerData] = useState(null);

  const getSelectedLockerData = async ({
    lockerId = null,
    startDate = null,
    endDate = null,
  }) => {
    if (lockerId && startDate && endDate) {
      const url = `/v3/admin/lockers/building-manager?from=${startDate}&to=${endDate}`;
      const body = { lockerId };
      setLoading(true);
      const response = await api.post(url, body);
      setLoading(false);
      const { data } = response;
      setSelectedLockerData(data);
    }
  };
  const reducer = (prev, next) => {
    if ((prev.startDate || prev.endDate) && (next.startDate || next.endDate)) {
      // Ensure end date is greater than start date
      if ((next.startDate || prev.startDate) > (next.endDate || prev.endDate)) {
        alert.error("A data final deve ser maior ou igual a data inicial.");
        return prev;
      }
      // Prevent user from selecting a range greater than 31 days
      const endDate = new Date(next.endDate || prev.endDate);
      const startDate = new Date(next.startDate || prev.startDate);
      const difference = endDate - startDate;
      const differenceInDays = Number.isNaN(difference)
        ? 0
        : Math.floor(difference / (1000 * 60 * 60 * 24));

      if (differenceInDays > 31) {
        alert.error(
          "A distância entre das datas não pode ser maior do que 31 dias."
        );
        return prev;
      }
    }
    // Ensure that all required fields are filled
    if (
      (next.selectedLocker || prev.selectedLocker) &&
      (next.startDate || prev.startDate) &&
      (next.endDate || prev.endDate)
    )
      setDisableButton(false);

    // Clear data when a different locker is selected
    if (
      next.selectedLocker &&
      next.selectedLocker?._id !== prev.selectedLocker?._id &&
      selectedLockerData
    )
      setSelectedLockerData(null);

    // Fetch data when loading the page
    if (next.selectedLocker && next.startDate && next.endDate)
      getSelectedLockerData({
        lockerId: next.selectedLocker?._id,
        startDate: next.startDate,
        endDate: next.endDate,
      });

    return { ...prev, ...next };
  };

  const [managerLockers, setManagerLockers] = useState([]);
  const [settings, setSettings] = useReducer(reducer, {
    selectedLocker: null,
    startDate: "",
    endDate: "",
  });
  const [dateRangeLimit, setDateRangeLimit] = useState({ min: "", max: "" });

  const { startDate, endDate, selectedLocker } = settings;
  const pageHeaderTitle = settings?.selectedLocker
    ? titleCase(settings?.selectedLocker?.name)
    : "Lockers";
  const pageHeaderSubTitle =
    settings?.selectedLocker && !settings?.selectedLocker?.all
      ? titleCase(
          `${settings?.selectedLocker?.address?.rua}, ${settings?.selectedLocker?.address?.numero} - ${settings?.selectedLocker?.address?.bairro}, ${settings?.selectedLocker?.address?.cidade}`
        )
      : null;

  useEffect(() => {
    document.title = `TuimBox Admin - Dashboard`;

    const managerLockersData = userIsAdmin
      ? [
          {
            _id: lockers.map((locker) => locker._id),
            address: null,
            description: "Todos",
            all: true,
          },
          ...lockers,
        ]
      : lockers;

    setManagerLockers(managerLockersData);

    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    const formattedCurrentMonth = currentMonth.toString().padStart(2, "0");
    const formattedCurrentDay = currentDay.toString().padStart(2, "0");

    if (!userIsAdmin) {
      const lastMonth = (currentMonth - 1).toString().padStart(2, "0");
      const min = `${currentYear}-${lastMonth}-01`;
      const max = `${currentYear}-${formattedCurrentMonth}-${formattedCurrentDay}`;
      setDateRangeLimit({ min, max });
    }

    if (lockers.length > 0) {
      const endDate = `${currentYear}-${formattedCurrentMonth}-${formattedCurrentDay}`;
      const startDate = userIsAdmin
        ? `${currentYear}-${formattedCurrentMonth}-01`
        : endDate;

      setSettings({
        selectedLocker: {
          ...managerLockersData?.[0],
          name: managerLockersData?.[0]?.description,
        },
        startDate,
        endDate,
      });
    }
  }, []);

  return (
    <>
      <SectionHeader
        title={pageHeaderTitle}
        subtitle={pageHeaderSubTitle}
        linkIcon={!selectedLocker?.all}
        linkIconHref={`https://www.tuimbox.com.br/locker/${selectedLocker?._id}`}
      />
      <ContentContainer>
        <Box sx={{ display: "flex", gap: 4 }}>
          <DropDown
            title="Locker"
            options={managerLockers?.map(renameField)}
            currentValue={selectedLocker}
            onClick={(value) => setSettings({ selectedLocker: value })}
            fullWidth
          />
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Text>De:</Text>
              <DatePicker
                value={startDate}
                min={dateRangeLimit?.min}
                max={dateRangeLimit?.max}
                onChange={({ target }) =>
                  setSettings({ startDate: target.value })
                }
                style={{
                  width: "160px",
                  padding: 14,
                  borderRadius: 8,
                  border: `1px solid #ddd`,
                  fontFamily: "Ubuntu",
                  color: "#444",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Text>Até:</Text>
              <DatePicker
                value={endDate}
                min={dateRangeLimit?.min}
                max={dateRangeLimit?.max}
                onChange={({ target }) =>
                  setSettings({ endDate: target.value })
                }
                style={{
                  width: "160px",
                  padding: 14,
                  borderRadius: 8,
                  border: `1px solid #ddd`,
                  fontFamily: "Ubuntu",
                  color: "#444",
                }}
              />
            </Box>

            <Box style={{ display: "flex" }}>
              <PrimaryButton
                text="Gerar Relatório"
                width="large"
                onClick={() =>
                  getSelectedLockerData({
                    lockerId: selectedLocker?._id,
                    startDate,
                    endDate,
                  })
                }
                disabled={disableButton}
              />
            </Box>
          </Box>
        </Box>
        <Divider distance={1} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <IndicatorCard
              title="Contratos"
              value={selectedLockerData?.contracts}
            />
            <IndicatorCard title="Faturas" value={selectedLockerData?.bills} />
            <IndicatorCard title="Usuários" value={selectedLockerData?.users} />
            <IndicatorCard
              title="Total"
              value={selectedLockerData?.amount}
              currency
            />
            <IndicatorCard
              title="Tuimpoints"
              value={selectedLockerData?.tuimpoints}
            />
            {userIsAdmin && (
              <IndicatorCard
                title="Inadimplentes"
                value={selectedLockerData?.defaulters}
              />
            )}
          </Box>
          <Box
            sx={{
              ...styles.indicatorCardContainer,
              flexDirection: "row",
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <EvaluationBar
              title="Limpeza"
              value={selectedLockerData?.cleaning}
            />
            <EvaluationBar
              title="Conservação"
              value={selectedLockerData?.conservation}
            />
            <EvaluationBar
              title="Custo/Benefício"
              value={selectedLockerData?.costBenefit}
            />
            <EvaluationBar
              title="Qualidade"
              value={selectedLockerData?.quality}
            />
            <EvaluationBar
              title="Avaliações"
              total
              value={selectedLockerData?.evaluations}
            />
          </Box>
        </Box>
        {selectedLockerData?.products?.length > 0 && (
          <Table
            size="small"
            header={productsTableHeader}
            rows={selectedLockerData?.products?.map((product) => {
              return {
                to: null,
                data: [
                  <Text>{product?.name}</Text>,
                  <Text>{product?.contracts}</Text>,
                  <Text>{numberToBRL(product?.amount)}</Text>,
                ],
              };
            })}
          />
        )}
      </ContentContainer>
    </>
  );
};

const IndicatorCard = ({ title = "", value = 0, currency = false }) => {
  return (
    <Box sx={styles.indicatorCardContainer}>
      <AnimatedNumber
        target={value}
        currency={currency}
        textStyle={{
          fontFamily: "UbuntuBold",
          fontSize: 18,
          color: Colors.darkGreen,
        }}
      />
      <Text style={{ color: "#888" }}>{title}</Text>
    </Box>
  );
};

const EvaluationBar = ({ title = "", value = 0, total = false }) => {
  if (total)
    return (
      <Box sx={{ ...styles.evaluationBarMainContainer, gap: 0 }}>
        <Text title="true" bold="true" style={{ color: Colors.darkGreen }}>
          {value}
        </Text>
        <Text style={{ color: "#888" }}>{title}</Text>
      </Box>
    );

  return (
    <Box sx={styles.evaluationBarMainContainer}>
      <Text style={{ color: "#888" }}>{title}</Text>
      <Box sx={{ ...styles.evaluationBarOuterContainer }}>
        <Box
          sx={{
            ...styles.evaluationBarInnerContainer,
            transition: `all 1s`,
            width: `${(value * 100) / 5}%`,
          }}
        />
      </Box>
    </Box>
  );
};

const styles = {
  indicatorCardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: Colors.background,
    padding: 3,
    flex: 1,
    borderRadius: 2,
    color: Colors.darkGreen,
  },
  datePickerLabel: {
    fontSize: 14,
    width: "50px",
  },
  evaluationBarMainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 2,
    flex: 1,
  },
  evaluationBarOuterContainer: {
    display: "flex",
    borderRadius: 2,
    backgroundColor: "#ddd",
    width: "100%",
    overflow: "hidden",
  },
  evaluationBarInnerContainer: {
    display: "flex",
    backgroundColor: Colors.lemonGreen + "bb",
    height: 6,
    justifyContent: "flex-end",
    alignItems: "center",
    borderRadius: 2,
  },
};
